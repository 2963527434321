.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .navbar-brand.abs
  {
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}

/* Solid border */
hr.solid {
  border-top: 3px solid #bbb;
}